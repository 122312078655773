import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Fields from '../Profile/Field'

export default class Labels extends PureComponent {
	render() {
		const { field } = this.props

		const fields = field.split(',')
		const fieldItem = fields.map(field => (
			<Fields key={field} label={field} />
		))
		// console.log(fieldItem)

		return <div>{fieldItem}</div>
	}
}

Labels.propTypes = {
	field: PropTypes.string,
}
