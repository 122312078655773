import React from 'react'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import { Spring } from 'react-spring/renderprops'
import Layout from '../../components/layout'
import style from './work.module.less'
import '../../utils/constant.less'
import Labels from '../../components/Labels'
import styled from 'styled-components'
import Img from 'gatsby-image'
import Banner from '../../components/Banner'

function getRandom(arr, n) {
	var result = new Array(n),
		len = arr.length,
		taken = new Array(len)
	if (n > len) return arr
	while (n--) {
		var x = Math.floor(Math.random() * len)
		result[n] = arr[x in taken ? taken[x] : x]
		taken[x] = --len in taken ? taken[len] : len
	}
	return result
}

export default function Template({ data }) {
	const { markdownRemark } = data
	const { frontmatter } = markdownRemark

	const Content = styled.div`
		padding-bottom: 2rem;
		blockquote {
			font-style: italic;
			width: 100%;
			margin: 0;
			p {
			line-height: 1.3;
			font-size: 3rem;
			}
		}
		p {
			margin: 10% 0;
		}

		@media screen and (max-width: 400px) {
			p {
				margin: 10% 10%;
			}
			p img {
				max-width: 60%
				padding-left: 20%;
			}
			h3 {
				margin: 10% 10%;
			}
		}

		p img {
			max-width: 80%;
			padding-left: 10%;
		}
		h3 {
			font-weight: bold;
			font-size: 16px;
		}
		img {
			margin: auto;
		}
		sup {
			font-size: 1.4rem;
			vertical-align: super;
			margin-left: 1rem;
		}
		a {
			text-decoration: underline;
		}
		.gatsby-resp-image-wrapper {
			line-height: 0;
			width: 150%;
			margin-top: 10%;
			max-width: none !important;
			margin-left: -25% !important;
		}
		.gatsby-resp-image-background-image {
			height: 0 !important;
		}
		.gatsby-resp-image-image {
			height: auto !important;
		}
	`

	return (
		<Layout>
			<Spring
				config={{ tension: 320, friction: 30, clamp: true }}
				from={{ opacity: 0, paddingTop: 200 }}
				to={{ opacity: 1, paddingTop: 0 }}
			>
				{props => (
					<div style={props}>
						<div className={style.heading}>
							<div>
								<h2 className={style.worktitle}>
									{frontmatter.title}
								</h2>
								<Link
									className={style.gradname}
									to={`/graduates/${frontmatter.graduate
										.replace(/[^0-9a-zA-Z\s'\-]+/g, '')
										.replace(/[\'\s]+/g, '-')
										.toLowerCase()}/`}
								>
									<h4>{frontmatter.graduate}</h4>
								</Link>
							</div>
							<div>
								<Labels field={frontmatter.category} />
							</div>
						</div>
						<div>
							{/* <img src="../../../assets/thumbnail.jpg"></img> */}
							<Img
								fluid={frontmatter.cover.childImageSharp.fluid}
							/>
						</div>
						<div className={style.info}>
							<Content
								dangerouslySetInnerHTML={{
									__html: markdownRemark.html,
								}}
							/>
						</div>

						{data.student ? (
							data.student.edges.map(
								({ node: student }, index) => (
									<div
										key={index}
										className={style.portfoliobg}
									>
										<h2 className={style.portfolio}>
											Interested in my work?
										</h2>
										<a
											className={style.cta}
											href={student.frontmatter.portfolio}
										>
											View Portfolio
										</a>
									</div>
								),
							)
						) : (
							<div />
						)}
						<div />

						<h2 className={style.workwork}>Explore more work</h2>

						<div className={style.projectContainer}>
							{data.project &&
								getRandom(data.project.edges, 1).map(
									({ node: project }) => (
										<div
											className={style.projectLink}
											key={project.id}
										>
											<Link to={project.frontmatter.path}>
												<Img
													className={style.projImage}
													fluid={
														project.frontmatter
															.cover
															.childImageSharp
															.fluid
													}
												/>
												<h5 className={style.category}>
													{
														project.frontmatter
															.category
													}
												</h5>
												<h3 className={style.projTitle}>
													{project.frontmatter.title}
												</h3>
												<h5
													className={
														style.studentname
													}
												>
													By{' '}
													{
														project.frontmatter
															.graduate
													}
												</h5>
											</Link>
										</div>
									),
								)}

							{data.projectByCategory &&
								getRandom(data.projectByCategory.edges, 1).map(
									({ node: projectByCategory }) => (
										<div
											className={style.projectLink}
											key={projectByCategory.id}
										>
											<Link
												to={
													projectByCategory
														.frontmatter.path
												}
											>
												<Img
													className={style.projImage}
													fluid={
														projectByCategory
															.frontmatter.cover
															.childImageSharp
															.fluid
													}
												/>
												<h5 className={style.category}>
													{
														projectByCategory
															.frontmatter
															.category
													}
												</h5>
												<h3 className={style.projTitle}>
													{
														projectByCategory
															.frontmatter.title
													}
												</h3>
												<h5
													className={
														style.studentname
													}
												>
													By{' '}
													{
														projectByCategory
															.frontmatter
															.graduate
													}
												</h5>
											</Link>
										</div>
									),
								)}
						</div>

						<div className={style.bg}>
							<Banner
								title="Like What You See?"
								subtitle="Meet the grads behind these projects In Real Life at the grad show on April 10 & 11 at Free Space!"
								cta="View Details"
							/>
						</div>
					</div>
				)}
			</Spring>
		</Layout>
	)
}

export const pageQuery = graphql`
	query($path: String!, $graduate: String!, $title: String!) {
		markdownRemark(frontmatter: { path: { eq: $path } }) {
			html
			frontmatter {
				path
				cover {
					childImageSharp {
						fluid(maxWidth: 1080, cropFocus: CENTER) {
							...GatsbyImageSharpFluid
						}
					}
				}
				title
				graduate
				category
			}
		}

		student: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { glob: "**/src/content/graduates/*.md" }
				frontmatter: { name: { regex: $graduate } }
			}
		) {
			edges {
				node {
					id
					frontmatter {
						portfolio
					}
				}
			}
		}

		project: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { glob: "**/src/content/work/*.md" }
				frontmatter: {
					graduate: { regex: $graduate }
					title: { ne: $title }
				}
			}
		) {
			edges {
				node {
					id
					frontmatter {
						path
						title
						graduate
						category
						cover {
							childImageSharp {
								fluid(maxWidth: 480, cropFocus: CENTER) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}

		projectByCategory: allMarkdownRemark(
			filter: {
				fileAbsolutePath: { glob: "**/src/content/work/*.md" }
				frontmatter: { graduate: { ne: $graduate } }
			}
		) {
			edges {
				node {
					id
					frontmatter {
						path
						title
						graduate
						category
						cover {
							childImageSharp {
								fluid(maxWidth: 480, cropFocus: CENTER) {
									...GatsbyImageSharpFluid
								}
							}
						}
					}
				}
			}
		}
	}
`
